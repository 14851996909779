import React from 'react'
import { Typography } from '@material-ui/core'
import Layout from '../components/Layout'

function NotFound () {
  return (
    <Layout>
      <Typography>Not found</Typography>
    </Layout>
  )
}


export default NotFound
